export class Member {
    [key: string]: any;

    _id: string = ''
    member_id: string = ''
    email: string = ''
    phone: string = ''
    display_name: string = ''
    first_name: string = ''
    last_name: string = ''
    school: number = 0
    role: Role = Role.NONE
    form: string = ''
    elective: number[] = []
    subjects: string[] = []
    status: string = ''
    title: number = -1
    dse: number = -1

    password_enabled: boolean = false;
    password: string = ''

    profile_picture_url = ''

    banned: boolean = false
    member_created_date: string = ''


    google_email: string = ''
    facebook_email: string = ''
    apple_email: string = ''
    google_id: string = ''
    facebook_id: string = ''
    apple_id: string = ''

    next_school_changeable = ''

    member_wallet = {}
    streak = {}
    cart = {}

    school_verification: VerificationStatus = VerificationStatus.NOT_SUBMITTED
    offline_paid: number = 0

    name: string = ''
    referral: boolean = false
    xban: XBan | null = null
}

export enum VerificationStatus {
    NOT_SUBMITTED = -2,
    REJECTED = -1,
    NOT_VERIFIED = 0,
    VERIFIED = 1
}

export enum Role {
    ADMIN = "ADMIN",
    SADMIN = "SADMIN",
    TUTOR = "TUTOR",
    GRADUATE = "GRADUATE",
    GRADUATE_UNVERIFIED = "GRADUATE_UNVERIFIED",
    GRADUATE_REJECTED = "GRADUATE_REJECTED",
    NONE = ""
}

export class XBan {
    _id: string; // member id
    days: number = 0; // gte 999 -> forever
    until: number = 0; // timestamp
    reason: string = "";
    by: string;
}
