import moment from 'moment'
import {Vue} from 'vue-property-decorator'

Vue.filter('formatDate', (value, filterFormat) => {
    if (value) {
        return moment(value).format(filterFormat || 'DD/MM/YYYY HH:mm:ss' || 'lll')
    }

    return ''
})
