





















import {Component, Vue} from 'vue-property-decorator'
import {VSheet} from 'vuetify/lib'
import DiedMascot from '~/components/app/DiedMascot.vue'

@Component({
    components: {
        DiedMascot,
        VSheet
    }
})
export default class AuthLayout extends Vue{

}
