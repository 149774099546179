import * as Sentry from '@sentry/vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css'
import _ from 'lodash'
import VueApexCharts from 'vue-apexcharts'
import Editor from 'vue-editor-js/src/index'
import VueLineClamp from 'vue-line-clamp'
import {Vue} from 'vue-property-decorator'
import PageHeader from '~/components/app/page-header.vue'
import CourseLink from '~/components/link/course-link.vue'
import OrderLink from '~/components/link/order-link.vue'
import PaginationRow from '~/components/pagination/pagination-row.vue'
import Pagination from '~/components/pagination/pagination.vue'
import CoursePopover from '~/components/popover/course-popover.vue'
import InstructorPopover from '~/components/popover/instructor-popover.vue'
import MemberPopover from '~/components/popover/member-popover.vue'
import BlogSelect from '~/components/select/blog-select.vue'
import CourseSelect from '~/components/select/course-select.vue'
import SeriesSelect from '~/components/select/el-series-select.vue'
import InstructorSelect from '~/components/select/instructor-select.vue'
import MemberSelect from '~/components/select/member-select.vue'
import SFSelect from '~/components/select/sf-select.vue'
import SubjectSelect from '~/components/select/subject-select.vue'
import '~/plugins/animate'
import '~/plugins/auth'
import {auth} from '~/plugins/auth'
import '~/plugins/clipboard'
import '~/plugins/permission'
import vuetify from '~/plugins/vuetify'
import Vuetify from 'vuetify'
import router from '~/router'
import store from '~/store'
import {setServer} from '~/utils/network-request'
import App from './app.vue'
import './filters/formatDate'

// import '~/assets/custom-theme/index.css';

Vue.component('page-header', PageHeader)
Vue.component('member-popover', MemberPopover)
Vue.component('course-popover', CoursePopover)
Vue.component('instructor-popover', InstructorPopover)
Vue.component('member-select', MemberSelect)
Vue.component('course-select', CourseSelect)
Vue.component('instructor-select', InstructorSelect)
Vue.component('subject-select', SubjectSelect)
Vue.component('blog-select', BlogSelect)
Vue.component('el-series-select', SeriesSelect)
Vue.component('sf-select', SFSelect)
Vue.component('order-link', OrderLink)
Vue.component('course-link', CourseLink)

Vue.component('apexchart', VueApexCharts)
Vue.component('pagination', Pagination)
Vue.component('pagination-row', PaginationRow)

Vue.config.productionTip = false
Vue.use(ElementUI, {locale})
Vue.use(Editor)
Vue.use(_)
Vue.use(VueLineClamp, {importCss: true})

//  Setup tracking
if (!process.env.VUE_APP_ENV_DEV && !process.env.VUE_APP_ENV_STAGING) {
    Sentry.init({
        Vue: Vue,
        dsn: 'https://3118f089cd434acf9d0791c98c0b7ff6@o306317.ingest.sentry.io/5544481',
    })
}

setServer(process.env.VUE_APP_DOMAIN_API!!)


/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/
export let app: Vue

function initApp() {
    if (!app) {
        app = new Vue({
            store,
            router,
            vuetify: vuetify,
            render: (h) => h(App),
        }).$mount('#app')
        try {
            const loader = document.getElementsByClassName('loading-placeholder')[0]
            loader.remove()
        } catch (e) {
            console.log('Remove loader failed!')
        }
    }
}

async function initUser(memberId: string) {
    return new Promise<void>(async (resolve, reject) => {
        if (memberId) {
            //  tracking user id
            if (window) {
                Sentry && Sentry.setUser({id: memberId})
            }
            await store.dispatch('user/fetchUserData', memberId)
            initApp()
            return resolve()
        }
    })
}

let urlParams = new URLSearchParams(window.location.search)

//  Remove frame with query
if (urlParams.has('noframe')) {
    store.dispatch('app/setNoFrame')
}

//  Check user is logged in and not dummy account
if (auth.isLoggedIn && auth.memberId !== 'DUMMY') {
    initUser(auth.memberId)
} else {
    initApp()
}





