

















































import {Component, Vue} from 'vue-property-decorator'
import {VAppBar, VAppBarNavIcon, VBtn, VCard, VMain, VNavigationDrawer, VSpacer} from 'vuetify/lib'
import {mapState} from 'vuex'
import MainMenu from '~/components/Navigation/MainMenu.vue'
import ToolbarUser from '~/components/Toolbar/ToolbarUser.vue'
import config from '~/configs'
import {toAdminv} from '~/utils/misc'

@Component({
    components: {
        MainMenu,
        VNavigationDrawer,
        VMain,
        VAppBar,
        VCard,
        VAppBarNavIcon,
        VSpacer,
        VBtn,
        ToolbarUser
    },
    metaInfo() {
        return {
            changed: (newInfo) => {
                this.title = newInfo.titleChunk
            }
        }
    }
})
export default class DefaultLayout extends Vue {
    drawer = null

    navigation = config.navigation


    title: string = ''

    async newAdmin() {
        await toAdminv('/')
    }
}
